@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

.css-m4m8x7 {
  background-color: rgb(243, 244, 246);
  padding-bottom: 0px !important;

}

.typ {
  border: none;

}

.typ:active {
  outline: none;
  border: none;
}

.typ:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none;
}

.custom-file-input {
  display: none !important;
}

.custom-file-label {
  display: inline-block !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  background-color: #007bff !important;
  color: white !important;
  font-size: 16px !important;
}

.custom-file-label i {
  margin-right: 5px !important;
}

/* -------------------------------------------scrollbar----------------------------------------------- */

/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.preview {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 10px !important;
}


/* =================================================================================================== */

.main-color {
  color: #418bcc !important;
}

.bg-color {
  background-color: #418bcc !important;
}

.text-filed {
  font-size: 13px !important;
}

body {
  font-family: Arial, sans-serif !important;
  background-color: #ffffff !important;
  margin: 0 !important;
  padding: 0 !important;
}



.search-bar {
  margin-bottom: 20px !important;
}

.search-bar input {
  width: 100% !important;
  padding: 10px !important;
  border-radius: 5px !important;
  border: 1px solid #ddd !important;
  font-size: 16px !important;
}

.conversation .message {
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #eee !important;
  padding: 10px 0 !important;
}

.conversation .message:last-child {
  border-bottom: none !important;
}

.avatar {
  width: fit-content !important;
  height: 40px !important;
  background-color: #ddd !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
}

.message-content {
  background-color: #e0dfdfd1 !important;
}

.username {
  font-weight: bold !important;
  margin-bottom: 5px !important;
}

.text {
  /* margin-bottom: 5px !important; */
}

.timestamp {
  color: #999 !important;
  font-size: 12px !important;
}

/* 
body {
  font-family: Arial, sans-serif !important;
  background-color: #f4f4f4 !important;
  margin: 0 !important;
  padding: 0 !important;
} */

.chat-container {
  width: 100% !important;
  max-width: 600px !important;
  margin: 0 auto !important;
  background-color: #fff !important;
  height: 100vh !important;
  display: flex !important;
  flex-direction: column !important;
}

.chat-header {
  display: flex !important;
  align-items: center !important;
  padding: 4px !important;
  background-color: #eee !important;
  border-bottom: 1px solid #ddd !important;
}

.back-button {
  font-size: 24px !important;
  cursor: pointer !important;
}

.chat-title {
  flex: 1 !important;
  text-align: center !important;
  font-weight: bold !important;
}

.chat-messages {
  flex: 1 !important;
  padding: 10px !important;
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
}

.message {
  display: flex !important;
  align-items: center !important;
}

.message.left {
  justify-content: flex-start !important;
}

.message.right {
  justify-content: flex-end !important;
}

.avatar {
  width: 40px !important;
  height: 40px !important;
  background-color: #ddd !important;
  border-radius: 50% !important;
  margin: 0 10px !important;
}

.message-content {
  width: fit-content !important;
  block-size: fit-content !important;
  padding: 5px !important;
  border-radius: 10px !important;
}

.message.left .message-content {
  background-color: #418bcc !important;
  color: white !important;
}

.message.right .message-content {
  background-color: #f1f1f1 !important;
  color: black !important;
}

.chat-input {
  display: flex !important;
  padding: 10px !important;
  background-color: #eee !important;
  border-top: 1px solid #ddd !important;
}

.chat-input input {
  flex: 1 !important;
  padding: 10px !important;
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.chat-input button {
  padding: 10px !important;
  border: none !important;
  background-color: #007bff !important;
  color: white !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}






h1 {
  margin-top: 0 !important;
}

.profile-pic {
  position: relative !important;
  display: inline-block !important;
  margin-bottom: 20px !important;
}

.profile-pic img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  background-color: #e0e0e0 !important;
}

.profile-info h2 {
  margin: 0 !important;
}

.profile-info p {
  color: #666 !important;
  margin: 5px 0 20px !important;
}

.profile-menu {
  list-style-type: none !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: left !important;
  font-size: 18px !important;
  color: #678178 !important;
  line-height: 30px !important;
}

.profile-menu li {
  margin-bottom: 15px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

/* .profile-info h2 {} */

.profile-menu li Link {
  text-decoration: none !important;
  color: #678178 !important;
  font-size: 18px !important;
}

.profile-menu li .push-notifications {
  font-size: 18px !important;
  color: #678178 !important;
}

.switch {
  position: relative !important;
  display: inline-block !important;
  width: 34px !important;
  height: 20px !important;
}

.switch input {
  display: none !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  transition: .4s !important;
  border-radius: 34px !important;
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 14px !important;
  width: 14px !important;
  left: 3px !important;
  bottom: 3px !important;
  background-color: white !important;
  transition: .4s !important;
  border-radius: 50% !important;
}

input:checked+.slider {
  background-color: #007bff !important;
}

input:checked+.slider:before {
  transform: translateX(14px) !important;
}

.container {
  text-align: center !important;
}

.c-buttuns {
  margin: 1px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  background-color: #007BFF !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
}

.list {
  display: none !important;
  list-style: none !important;
  padding: 0 !important;
}

.list.active {
  display: block !important;
}

.copyright {
  display: block !important;
  margin-top: 100px !important;
  text-align: center !important;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
}

.copyright a {
  text-decoration: none !important;
  color: #EE4E44 !important;
}


/****** CODE ******/

.file-upload {
  display: block !important;
  text-align: center !important;
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
}

.file-upload .file-select {
  display: block !important;
  border: 2px solid #dce4ec !important;
  color: #34495e !important;
  cursor: pointer !important;
  height: 40px !important;
  line-height: 40px !important;
  text-align: left !important;
  background: #FFFFFF !important;
  overflow: hidden !important;
  position: relative !important;
}

.file-upload .file-select .file-select-button {
  background: #dce4ec !important;
  padding: 0 10px !important;
  display: inline-block !important;
  height: 40px !important;
  line-height: 40px !important;
}

.file-upload .file-select .file-select-name {
  line-height: 40px !important;
  display: inline-block !important;
  padding: 0 10px !important;
}

.file-upload .file-select:hover {
  border-color: #34495e !important;
  transition: all .2s ease-in-out !important;
  -moz-transition: all .2s ease-in-out !important;
  -webkit-transition: all .2s ease-in-out !important;
  -o-transition: all .2s ease-in-out !important;
}

.file-upload .file-select:hover .file-select-button {
  background: #34495e !important;
  color: #FFFFFF !important;
  transition: all .2s ease-in-out !important;
  -moz-transition: all .2s ease-in-out !important;
  -webkit-transition: all .2s ease-in-out !important;
  -o-transition: all .2s ease-in-out !important;
}

.file-upload.active .file-select {
  border-color: #3fa46a !important;
  transition: all .2s ease-in-out !important;
  -moz-transition: all .2s ease-in-out !important;
  -webkit-transition: all .2s ease-in-out !important;
  -o-transition: all .2s ease-in-out !important;
}

.file-upload.active .file-select .file-select-button {
  background: #3fa46a !important;
  color: #FFFFFF !important;
  transition: all .2s ease-in-out !important;
  -moz-transition: all .2s ease-in-out !important;
  -webkit-transition: all .2s ease-in-out !important;
  -o-transition: all .2s ease-in-out !important;
}

.file-upload .file-select input[type=file] {
  z-index: 100 !important;
  cursor: pointer !important;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
}

.file-upload .file-select.file-select-disabled {
  opacity: 0.65 !important;
}

.file-upload .file-select.file-select-disabled:hover {
  cursor: default !important;
  display: block !important;
  border: 2px solid #dce4ec !important;
  color: #34495e !important;
  cursor: pointer !important;
  height: 40px !important;
  line-height: 40px !important;
  margin-top: 5px !important;
  text-align: left !important;
  background: #FFFFFF !important;
  overflow: hidden !important;
  position: relative !important;
}

.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec !important;
  color: #666666 !important;
  padding: 0 10px !important;
  display: inline-block !important;
  height: 40px !important;
  line-height: 40px !important;
}

.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px !important;
  display: inline-block !important;
  padding: 0 10px !important;
}

/* --------------------------------------------------------------------------------------------- */

.modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 35px !important;
}

.modal2 {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modal-content {
  background-color: #fff !important;
  padding: 20px !important;
  border-radius: 8px !important;
  text-align: center !important;
}

.modal2-content {
  background-color: #fff !important;
  /* padding: 50px !important; */
  border-radius: 8px !important;
  text-align: center !important;
  box-shadow: inset 0 4px 0 0 #007bff !important;
}

.modal-content button {
  margin: 5px !important;
}

.close {
  font-size: 30px !important;
  top: -30px !important;
  bottom: 400px !important;
  right: -0px !important;
  margin: 10px !important;
}

.send-button {
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  padding: 10px !important;
  cursor: pointer !important;
  margin-bottom: 10px !important;
  margin-left: 216px !important;
  border-radius: 10px !important;
}

.send-button:hover {
  background-color: #007bfff3 !important;
}

.yes {
  color: white !important;
  padding: 1px 20px !important;
  background-color: #ff0000 !important;
  border-radius: 5px !important;
}

.no {
  color: white !important;
  padding: 1px 20px !important;
  border-radius: 5px !important;
  margin-left: 12px !important;
  background-color: #0095ff !important;
}

/* ----------------------------------------------------------------------------------- */

/* CSS */
.button-7 {
  background-color: #0095ff !important;
  border: 1px solid transparent !important;
  border-radius: 3px !important;
  box-shadow: rgba(255, 255, 255, .4) 0 1px 0 0 inset !important;
  box-sizing: border-box !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 1.15385 !important;
  margin: 0 !important;
  outline: none !important;
  padding: 8px .8em !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
  vertical-align: baseline !important;
  white-space: nowrap !important;
}

.button-7:hover,
.button-7:focus {
  background-color: #07c !important;
}

.button-7:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15) !important;
}

.button-7:active {
  background-color: #0064bd !important;
  box-shadow: none !important;
}

/* ------------------------------------------------------------------------------------------------------------------------------- */

.fileUpload {
  background: #00bcbe !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  color: #fff !important;
  font-size: 1em !important;
  font-weight: bold !important;
  margin: 1.25em auto !important;
  /*20px/16px 0*/
  overflow: hidden !important;
  padding: 0.875em !important;
  /*14px/16px*/
  position: relative !important;
  text-align: center !important;
  width: 120px !important;
  cursor: pointer !important;
  position: absolute !important;
  bottom: -25px !important;
  right: -5px !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important; */
}

.fileUpload2 {
  background: #00bcbe !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  border-radius: 15px !important;
  color: #fff !important;
  font-size: 1em !important;
  font-weight: bold !important;
  margin: 1.25em auto !important;
  /*20px/16px 0*/
  overflow: hidden !important;
  padding: 0.875em !important;
  /*14px/16px*/
  position: relative !important;
  text-align: center !important;
  width: 120px !important;
  cursor: pointer !important;
  position: absolute !important;
  /* bottom: px !important; */
  right: 80px !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important; */
}

.fileUpload input.upload {
  position: absolute !important;
  /* top: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  padding: 0 !important; */
  font-size: 20px !important;
  cursor: pointer !important;
  opacity: 0 !important;
  /* width: 148px !important; */
  /* height: 56px !important; */
  cursor: pointer !important;
}

.fileUpload2 span {
  font-size: 30px !important;
  color: grey !important;
}

.fileUpload2 input.upload {
  position: absolute !important;
  /* top: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  padding: 0 !important; */
  font-size: 20px !important;
  cursor: pointer !important;
  opacity: 0 !important;
  /* width: 148px !important; */
  /* height: 56px !important; */
  cursor: pointer !important;
}

.fileUpload span {
  font-size: 30px !important;
  color: grey !important;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  /* Add custom styles */
  font-size: 16px !important;
  /* border: 1px solid #ccc !important; */
  background-color: #fff !important;
  /* color: #6b6b6b9b !important; */
  cursor: pointer !important;
  /* Optional: Add more custom styling as needed */
}

.p-color {
  color: red !important;
}

.card-border {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

/* ================================================================================================================================== */

.loader {
  border: 4px solid #f3f3f3 !important;
  border-top: 4px solid #3498db !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  animation: spin 2s linear infinite !important;
  text-align: center !important;
  padding: 10px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) !important;
  }

  100% {
    transform: rotate(360deg) !important;
  }
}

.setloader {
  border: 4px solid rgba(0, 0, 0, 0.1) !important;
  border-top: 4px solid #3498db !important;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
  animation: spiner 1s linear infinite !important;
  margin-left: 5px !important;
  /* Adjust as needed */
}

@keyframes spiner {
  0% {
    transform: rotate(0deg) !important;
  }

  100% {
    transform: rotate(360deg) !important;
  }
}

/* ----------------------------------------------------------------------------------------------------------------------------- */

.top-card-border {
  box-shadow: inset 0 4px 0 0 #007bff,
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 8px 20px rgba(0, 0, 0, 0.1) !important;
}
